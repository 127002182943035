<template>
  <div class="portalcx-select-plan">
    <label class="portalcx-select-plan--label">Select Your Plan</label>
    <div
      class="portalcx-select-plan--plans d-flex flex-wrap"
      :class="{ 'full-width-last-one': planItems.length % 2 !== 0 }"
    >
      <div
        v-for="(plan, planIndex) in planItems"
        :key="planIndex"
        class="plan-item d-flex"
        :class="{
          selected: plan.planName == selectedPlan.planName
        }"
        @click="selectPlanItem(plan)"
      >
        <div class="plan-item--selector d-flex align-items-center justify-content-center">
          <img
            v-if="plan.planName == selectedPlan.planName"
            src="@/assets/images/plan-selector-selected.svg"
            alt="plan selector"
          />
          <img
            v-else
            src="@/assets/images/plan-selector.svg"
            alt="plan selector"
          />
        </div>
        <div class="plan-item--content">
          <p>{{ plan.planName }}</p>
          <p>
            <span>{{ plan.description }}</span>
          </p>
        </div>
        <div class="plan-item--price">
          {{ plan.price }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectPlan",
  props: {
    plans: {
      type: Array,
      default: () => []
    },
    selectedPlan: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    planItems() {
      const yearlyFromUrl = this.$route.query.yearly;
      var billingPeriod = "mo";
      if(yearlyFromUrl == "true")
      {
        billingPeriod = "year";
      }
      let items = this.plans.map(item => ({
        ...item,
        planName: item.productName,
        price: `$${item.unitAmount / 100}/${billingPeriod}`
      }));

      return items;
    },
  },
  methods: {
    selectPlanItem(plan) {
      this.$emit('selected', plan);
    }
  },
  watch: {
    planItems: {
      handler(plans) {
        console.log("this.selectedPlan", this.selectedPlan)
        if (plans.length > 0 && !this.selectedPlan.productName) {
          console.log("plans.length", plans.length)
          this.selectPlanItem(plans[0]);
        }
        else
        {
          // Get the plan with the same name as this.selectedPlan.planName
          let sameNamePlan = plans.find(plan => plan.planName === this.selectedPlan.productName);

          // If a plan with the same name was found, select it
          if (sameNamePlan) {
            this.selectPlanItem(sameNamePlan);
            console.log("If a plan with the same name was found, select it")
          }
        }
      },
      deep: true,
      immediate: true,
    },
    
    // Add a watcher on $route.query.plan_name to handle changes in the URL parameter
    '$route.query.plan_name': {
      handler(newPlanName) {
        if (newPlanName && this.planItems.length > 0) {
          const planFromUrl = this.planItems.find(plan => plan.planName === newPlanName);
          if (planFromUrl) {
            this.selectPlanItem(planFromUrl);
          }
        }
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss">
.portalcx-select-plan {
  &--label {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #333;
    margin-bottom: 7px;
    &.error-state {
      color: #dc3545;
    }
  }
  &--plans {
    gap: 12px 8px;
    
    .plan-item {
      width: calc(50% - 4px);
      max-width: calc(50% - 4px);
      gap: 8px;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid #D1D5DB;
      padding: 12px;

      &--selector {
        width: 20px;
        height: 24px;
        img {
          width: 20px;
          height: 20px;
        }
      }

      &--content {
        flex: 1;
        > p {
          margin: 0;
          white-space: nowrap;
          &:nth-child(1) {
            color: black;
            font-size: 14px;
            line-height: 17px;
            font-weight: 600;
            margin-bottom: 8px;
          }
          &:nth-child(2) {
            height: 15px;
            position: relative;
            
            > span {
              color: #71717A;
              font-size: 12px;
              line-height: 15px;
              white-space: nowrap;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }

      &--price {
        color: black;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
      }

      &.selected {
        border: 1px solid #3CE795;
      }
    }

    &.full-width-last-one {
      .plan-item:last-child {
        width: 100%;
        max-width: 100%;
      }
    }

    @media screen and (max-width: 767px) {
      gap: 4px;

      .plan-item {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>