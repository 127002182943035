import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { email, required, confirmed } from 'vee-validate/dist/rules';
import { phone } from 'phone';

extend('email', {
    ...email,
    message: 'Please enter valid Email.',
});

extend("confirmed", {
    ...confirmed,
    message: 'Passwords must be identical'
});

extend('required', {
    ...required,
    message: 'This field is required.',
});

extend('validAccountPassword', value => {
     const validPassRegx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[\x21-\x7E]{8,}$/;
    if(validPassRegx.test(value)) {
        return true;
    }
    return 'Password should be more than 8 characters and include at least 1 special character, 1 uppercase letter, 1 lowercase letter and 1 digit.';
});

extend('validPhoneNumber', value => {
    if (phone(value).isValid) {
        return true
    }
    return 'Please enter valid Phone Number.'
})

extend('maxMessageLen', {
    validate(value, { length }) {
        return value.length < +length;
    },
    params: ['length', 'fieldName'],
    message: (fieldName, placeholders) => {
        return `${placeholders.fieldName} must be less than ${placeholders.length} characters.`;
    }
});

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
