import request from '@/request'

export function register(data) {
  return request({
    url: '/api/AuthManagement/Register',
    method: 'post',
    data
  })
}

export function getBillingModes(yearly=false) {
  return request({
    url: '/api/AuthManagement/billing/billingmodes?yearly='+yearly,
    method: 'get',
  })
}

export function createSubscription(data) {
  return request({
    url: '/api/AuthManagement/billing/subscription/create',
    method: 'post',
    data
  })
}
