export const formFieldMixin = {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    }
  },
  computed: {
    state() {
      if (this.error) {
        return !this.error;
      }
    },
    attrs() {
      const { ...attrs } = this.$attrs;
      return attrs;
    },
    listeners() {
      const { ...listeners } = this.$listeners;
      return listeners;
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    }
  }
}