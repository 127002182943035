<template>
    <b-form-group
      class="form-input"
      :class="{
        'form-password-input': isPassword
      }"
      :description="description"
      :invalid-feedback="error"
      :state="state"
    >
      <label
        v-if="label"
        class="form-input--label"
        :class="{
          'error-state': state === false
        }"
      >{{ label }}</label>
      <template v-if="isPassword">
        <b-form-input
          :type="showPassword ? 'text' : 'password'"
          :value="value"
          class="form-input--editor password"
          :class="`size-${size}`"
          :placeholder="placeholder"
          :state="state"
          @input="input"
          v-bind="attrs"
          autocomplete="off"
          v-on="listeners"
        />
        <div class="show-password-wrapper" @click="showPassword = !showPassword">
          <b-icon :icon="showPassword ? 'eye-slash-fill' : 'eye-fill'" />
        </div>
      </template>
      <b-form-input
        v-else
        :type="type"
        :value="value"
        class="form-input--editor"
        :class="`size-${size}`"
        :placeholder="placeholder"
        :state="state"
        @input="input"
        autocomplete="off"
        v-bind="attrs"
        v-on="listeners"
      ></b-form-input>
    </b-form-group>
</template>

<script>
import { formFieldMixin } from '@/mixins/form-field-mixin'

export default {
  name: 'FormInput',
  mixins: [formFieldMixin],
  components: {
  },
  props: {
    value: [String, Number],
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: 'default',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPassword: false,
    }
  },
  created() {
    this.showPassword = false
  },
  computed: {
    isPassword() {
      return this.type === 'password'
    },
  },
  methods: {   
  },
}
</script>

<style lang="scss">
.form-input {
  margin-bottom: 0 !important;
  &--label {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #333;
    margin-bottom: 7px;
    &.error-state {
      color: #dc3545;
    }
  }
  &--editor {
    padding: 14px 18px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
    line-height: 18px !important;
    color: #333 !important;
    &.password {
      padding-right: 46px !important;
    }
    &.is-invalid {
      background-image: none !important;
    }
    &.size-default {
      height: 46px !important;
      border-radius: 8px;
      border: 2px solid #E8E8E8;
    }
    &::placeholder {
      color: #888 !important;
    }
  }
  &.form-password-input {
    > div {
      position: relative;
      .show-password-wrapper {
        cursor: pointer;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 46px;
        height: 46px;
        top: 28px;
        right: 0;
      }
    }
  }
}
</style>
