<template>
  <div class="portalcx-notfound-page">
    <div class="d-flex align-items-center justify-content-center">
      <h1>
        Page Not Found
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  created() {
    document.title = 'PortalCX - Page Not Found';
  },
}
</script>

<style lang="scss">
.portalcx-notfound-page {
  background-image: url('../assets/images/portalcx-back1.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  > div {
    min-height: 100vh;
    background: linear-gradient(144.26deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
    @media (max-width: 1200px) {
      background: linear-gradient(144.26deg, #FFFFFF 0%, rgba(#FFFFFF, 0.9) 100%);
    }
  }
  @media (max-width: 1200px) {
    background-position: top right;
    background-size: cover;
  }
}
</style>
