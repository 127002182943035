<template>
  <div class="card-information" :class="{ 'error-state': isInvalid }">
    <label class="card-information--label">Card Information</label>
    <div
      ref="cardEle"
      class="card-information--element"
      style="padding: 0 18px; background: white"
    ></div>
    <div v-if="errorMsg" class="card-information--error">
      {{ errorMsg }}
    </div>
  </div>
</template>

<script>
const stripe = window.Stripe(
  process.env.NODE_ENV === 'development'
    ? process.env.VUE_APP_STRIPE_TEST_PK
    : process.env.VUE_APP_STRIPE_PROD_PK
)
export default {
  name: "CardInformation",
  props: {
    validationMsgs: {
      type: Object,
      default: () => ({
        'required': 'Your card information is required.',
        'incomplete': 'Your card information is incomplete.'
      })
    },
    stripeStyle: {
      type: Object,
      default: () => ({
        base: {
          color: '#333',
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          lineHeight: '42px',
          '::placeholder': {
            color: '#888',
          },
        },
        invalid: {
          color: '#dc3545',
          iconColor: '#dc3545',
        }
      })
    }
  },
  data() {
    return {
      card: null,
      errorMsg: null,
      cardChanged: false,
      cardCompleted: false,
      cardFirstFocused: false,
    }
  },
  computed: {
    stripeElements () {
      return stripe.elements();
    },
    isInvalid() {
      return !!this.errorMsg;
    }
  },
  mounted() {
    this.card = this.stripeElements.create(
      'card',
      {
        style: this.stripeStyle,
        hidePostalCode: true,
      }
    );
    this.card.mount(this.$refs.cardEle);
    this.card.on('change', (evt) => {
      this.cardChanged = true
      const { error, complete, empty } = evt
      this.errorMsg = null;
      this.cardCompleted = false;
      if (error) {
        this.errorMsg = error.message;
        this.cardCompleted = false;
      } else {
        if (!complete) {
          this.errorMsg = empty ? this.validationMsgs['required'] : this.validationMsgs['incomplete']
          this.cardCompleted = false;
        } else {
          this.errorMsg = null;
          this.cardCompleted = true;
        }
      }
    })
    this.card.on('blur', () => {
      if (!this.cardChanged) {
        this.errorMsg = this.validationMsgs['required']
      }
    })
  },
  methods: {
    async createPaymentMethod(billingDetails) {
      if (this.cardCompleted) {
        const res = await stripe.createPaymentMethod(
          {
            type: 'card',
            card: this.card,
            billing_details: {...billingDetails},
          },
        );

        return res.paymentMethod;
      }

      if (!this.errorMsg) {
        this.errorMsg = this.validationMsgs['required']
      }

      return null;
    }
  },
  beforeDestroy() {
    this.card.destroy();
  }
}
</script>

<style lang="scss">
.card-information {
  width: 100%;
  &--label {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #333;
    margin-bottom: 7px;
  }
  &--element {
    width: 100%;
    height: 46px;
    border: 2px solid #E8E8E8;
    border-radius: 8px;
  }

  &.error-state {
    .card-information--label {
      color: #dc3545;
    }

    .card-information--element {
      border: 2px solid #dc3545;
    }
  }

  &--error {
    color: #dc3545;
    font-size: 0.8rem;
    line-height: 1;
    margin-top: 4px;
  }
}
</style>