import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

let requestNum = 0

service.interceptors.request.use(
  config => {
    if (config.loading === undefined) {
      if (requestNum === 0) {
        store.commit('app/setAppLoading', true)
      }
      requestNum++
    }
    // Do something before request is sent
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (response.config.loading === undefined) {
      requestNum--
      if (requestNum === 0) {
        store.commit('app/setAppLoading', false)
      }
    }
    return response
  },
  error => {
    requestNum--
    if (requestNum === 0) {
      store.commit('app/setAppLoading', false)
    }
    return Promise.reject(error)
  }
)

export default function (config) {
  return service(config)
    .catch(err => {
      return Promise.reject(err)
    })
    .then(req => {
      return req
    })
}