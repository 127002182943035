<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <div id="app">
      <router-view></router-view>
    </div>
  </b-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState({
      loading: state => state.app.appLoading,
    })
  },
  mounted() {
    console.log(process.env.NODE_ENV)
    // this.$store.commit('account/setSignUpStep', 3)
  }
}
</script>
