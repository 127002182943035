<template>
  <div class="country-region">
    <label class="country-region--label">
      Country or Region
    </label>
    <div class="country-region--editor d-flex flex-column">
      <b-form-select
        class="country-select"
        :value="countryCode"
        :options="countryList"
        value-field="code"
        text-field="country"
        @change="changeCountryCode"
      ></b-form-select>
      <b-form-input
        class="zip-input"
        :value="zipCode"
        placeholder="ZIP"
        autocomplete="off"
        @change="changeZipCode"
      ></b-form-input>
    </div>
  </div>
</template>

<script>
import countryCodes from '@/iso-country-codes'

export default {
  name: "CountryRegion",
  props: {
    countryCode: {
      type: String,
      default: 'US',
    },
    zipCode: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      countryList: []
    };
  },
  created() {
    this.countryList = Object.entries(countryCodes).map(([key, value]) => {
      return {
        country: value,
        code: key,
      }
    });
  },
  methods: {
    changeCountryCode(evt) {
      this.$emit('change', {
        countryCode: evt,
        zipCode: this.zipCode,
      })
    },
    changeZipCode(evt) {
      this.$emit('change', {
        zipCode: evt,
        countryCode: this.countryCode,
      })
    }
  }
}
</script>

<style lang="scss">
.country-region {
  width: 100%;

  &--label {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    color: #333;
    margin-bottom: 7px;
  }

  &--editor {
    width: 100%;
    .country-select {
      width: 100%;
      height: 40px;
      font-family: "Source Sans Pro", sans-serif !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: #333 !important;
      border-radius: 8px 8px 0 0;
      border: 2px solid #E8E8E8;
      border-bottom: 1px solid #E8E8E8;
      &:focus {
        z-index: 1;
      }
    }
    .zip-input {
      width: 100%;
      height: 40px;
      font-family: "Source Sans Pro", sans-serif !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: #333 !important;
      border-radius: 0 0 8px 8px;
      border: 2px solid #E8E8E8;
      border-top: 1px solid #E8E8E8;
      &::placeholder {
        color: #888 !important;
      }
      &:focus {
        z-index: 1;
      }
    }
  }
}
</style>