<template>
  <div class="portalcx-signup-success-page">
    <div class="d-flex flex-column justify-content-center align-items-center">

      <div class="portalcx-signup-success-page--logo">
        <img src="@/assets/images/portalcx-logo.svg" alt="PortalCX Logo">
      </div>

      <h3>
        Signing up your PortalCX account went successfully!
      </h3>

      <p><a href="https://admin.portalcx.com/sign-in">Go to your admin portal and sign in your account</a></p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpSuccessPage',
  created() {
    setTimeout(() => {
      window.location.href = "https://admin.portalcx.com/sign-in";
    }, 5000)
  },
}
</script>

<style lang="scss">
.portalcx-signup-success-page {
  background-image: url('../assets/images/portalcx-back1.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  > div {
    min-height: 100vh;
    background: linear-gradient(144.26deg, #FFFFFF 25.71%, rgba(255, 255, 255, 0.0001) 100%);
    padding: 0 12px;
    
    > h3 {
      text-align: center;
    }

    > p {
      text-align: center;
      a {
        font-weight: 400;
        color: #2784BF;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &--logo {
    margin-bottom: 30px;
  }

  @media (max-width: 1200px) {
    background-position: top right;
    background-size: cover;
  }
}
</style>