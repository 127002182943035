import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import '@/scss/app.scss'
import './vue-vee-validate';

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin, BIcon } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('BIcon', BIcon)

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
