import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import SignUpPage from '@/views/SignUpPage'
import EnterpriseSignUpPage from '@/views/EnterpriseSignUpPage'
import FreeTrialPage from '@/views/FreeTrialPage'
import NotFoundPage from '@/views/NotFoundPage'
import SignUpSuccessPage from '@/views/SignUpSuccessPage'

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/signup',
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUpPage,
    },
    {
      path: '/free-trial',
      name: 'free-trial',
      component: FreeTrialPage,
    },
    {
      path: '/enterprise',
      name: 'enterprise',
      component: EnterpriseSignUpPage,
    },
    {
      path: '/signup-success',
      name: 'signup-success',
      component: SignUpSuccessPage,
      meta: { isAccountCreated: true },
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFoundPage,
    }
  ],
})

router.beforeEach((to, from, next) => {
  const urlParams = new URLSearchParams(window.location.search);
  const irclickid = urlParams.get('irclickid');
  if (irclickid) {
    store.dispatch('app/setIrclickid', irclickid);
  }

  if (to.matched.some((record) => record.meta.isAccountCreated)) {
    if (store.getters['account/getIsAccountCreated']) {
      next();
    } else {
      next({ name: 'NotFound' });
    }
  } else {
    next();
  }
  return;
})

export default router;
