<template>
  <div class="portalcx-free-trial-page">
    <div class="d-flex flex-column justify-content-md-start justify-content-md-center align-items-center">

      <div class="portalcx-free-trial-page--logo d-none d-md-block">
        <img src="@/assets/images/portalcx-logo.svg" alt="PortalCX Logo">
      </div>

      <div class="portalcx-free-trial-page--content d-flex flex-column flex-md-row">
        <div class="portalcx-free-trial-page--description d-flex flex-column align-items-center justify-content-center">
          <img src="@/assets/images/icon-portal-black.svg" alt="PortalCX Logo">
          <p>Try PortalCX, no credit card required</p>
          <p>Free for 14 Days</p>
          <p>{{ freeTrialDescription }}</p>
          <span class="tos-privacy d-none d-md-block">
            <a href="https://www.portalcx.com/terms-of-service" target="_blank">Terms of Service</a> | <a href="https://www.portalcx.com/privacy-policy" target="_blank">Privacy Policy</a>
          </span>
        </div>

        <div class="portalcx-free-trial-page--form">
          <div class="account-information">
            <validation-observer ref="accountInfoForm" v-slot="{ passes }">
              <b-form @submit.prevent="passes(startTrial)" autocomplete="off">
                <div class="account-information-row">
                  <div class="account-information-item">
                    <validation-provider vid="firstName" v-slot="{ errors }" rules="required">
                      <FormInput
                        v-model="form.firstName"
                        label="First Name"
                        :error="errors[0]"
                      />
                    </validation-provider>
                  </div>
                  <div class="account-information-item">
                    <validation-provider vid="lastName" v-slot="{ errors }" rules="required">
                      <FormInput
                        v-model="form.lastName"
                        label="Last Name"
                        :error="errors[0]"
                      />
                    </validation-provider>
                  </div>
                </div>
                <div class="account-information-row">
                  <div class="account-information-item">
                    <validation-provider vid="email" v-slot="{ errors }" rules="required|email">
                      <FormInput
                        v-model="form.email"
                        label="Email Address"
                        :error="errors[0]"
                      />
                    </validation-provider>
                  </div>
                  <div class="account-information-item">
                    <validation-provider vid="phone" v-slot="{ errors }" rules="required|validPhoneNumber">
                      <FormInput
                        v-model="form.phone"
                        label="Phone"
                        :error="errors[0]"
                      />
                    </validation-provider>
                  </div>
                </div>
                <div class="account-information-item">
                  <validation-provider vid="companyName" v-slot="{ errors }" rules="required">
                    <FormInput
                      v-model="form.companyName"
                      label="Company"
                      :error="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="account-information-item">
                  <validation-provider vid="password" v-slot="{ errors }" rules="required|validAccountPassword">
                    <FormInput
                      v-model="form.password"
                      label="Password"
                      type="password"
                      :error="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="account-information-item">
                  <SelectPlan
                    :plans="plans"
                    :selectedPlan="selectedPlan"
                    @selected="handleSelectPlan"
                  />
                </div>
                <b-button
                  type="submit"
                  variant="primary"
                  class="account-information-item submit-button"
                >
                  Start free trial
                </b-button>
              </b-form>
            </validation-observer>
          </div>

          <p class="already-have-account">
            Already have an account? <a href="https://admin.portalcx.com/sign-in" target="_blank">Sign in</a>
          </p>

          <span class="tos-privacy d-block d-md-none">
            <a href="https://www.portalcx.com/terms-of-service" target="_blank">Terms of Service</a> | <a href="https://www.portalcx.com/privacy-policy" target="_blank">Privacy Policy</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/apis'
import FormInput from '@/components/form-input.vue'
import SelectPlan from '@/components/select-plan.vue'

export default {
  name: "FreeTrialPage",
  components: {
    FormInput,
    SelectPlan,
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
        password: '',
      },
      plans: [],
      selectedPlan: {},
      freeTrialDescriptions: {},
      projectLimitOptions: {
        'Starter': 5,
        'Standard': 25,
        'Premium': 50,
      },
      projectLimitOptionsYearly: {
        'Starter Annual': 60,
        'Standard Annual': 300,
        'Premium Annual': 600,
      }
    }
  },
  computed: {
    freeTrialDescription() {
      if (this.selectedPlan.planName) {
        return this.freeTrialDescriptions[this.selectedPlan.planName];
      }
      return '';
    },
  },
  created() {
    document.title = 'PortalCX - Free Trial';
    this.$store.commit('account/setIsAccountCreated', false);
    const yearlyFromUrl = this.$route.query.yearly;
    API.account.getBillingModes(yearlyFromUrl).then(res => {
      this.plans = [...res.data];
      this.plans.forEach((plan) => {
        const { productName, unitAmount } = plan;
         // Get the plan_name parameter from the URL
        const planNameFromUrl = this.$route.query.plan_name;
        
        // If a plan_name was provided and the planItems array is ready
        if (planNameFromUrl) {
          // Find the plan with the same name
          const planFromUrl = plan.productName === planNameFromUrl;

          // If a matching plan was found, select it
          if (planFromUrl) {
            this.selectedPlan = plan
            console.log("this.selectedPlan", this.selectedPlan)
          }
        }
        var billingPeriod = "mo";
        if(yearlyFromUrl == "true")
        {
          billingPeriod = "year";
          this.projectLimitOptions= this.projectLimitOptionsYearly;
        }
        this.freeTrialDescriptions[productName] = `Then just $${unitAmount / 100}/${billingPeriod} for up to ${this.projectLimitOptions[productName]} Projects`
      })
    })
  },
  '$route.query.yearly': {},
  methods: {
    async startTrial() {
      try {
        const { firstName, lastName, password, companyName, phone, email } = this.form;
        const irclickid = this.$store.getters['app/getIrclickid'];
        const planName = this.selectedPlan.planName
        const planAmount = this.selectedPlan.price
        const planType = window.location.pathname.substring(1);
        const accountRes = await API.account.register({
          firstName,
          lastName,
          password,
          companyName,
          ContactPhone: phone,
          Email: email,
          irclickid,
          planName,
          planAmount,
          planType
        });
        
        const { stripeCustomerId, companyId } = accountRes.data;

        await API.account.createSubscription({
          companyId,
          stripeCustomerId,
          stripePriceId: this.selectedPlan.priceId,
        })

        setTimeout(() => {
          this.$store.commit('account/setIsAccountCreated', true);
          this.$router.push('/signup-success');
        }, 1000);
      } catch(e) {
        console.error(e)
      }
    },
    handleSelectPlan(plan) {
      this.selectedPlan = { ...plan };
    },
  }
}
</script>

<style lang="scss">
.portalcx-free-trial-page {
  background-image: url('../assets/images/portalcx-back1.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  > div {
    min-height: 100vh;
    background: linear-gradient(144.26deg, #FFFFFF 25.71%, rgba(255, 255, 255, 0.0001) 100%);
    padding: 0 12px;
    @media screen and (max-width: 767px) {
      background: none;
      padding: 0;
    }
  }

  &--logo {
    margin: 93px 0 30px;
  }

  &--content {
    width: 100%;
    max-width: 1000px;
    margin-bottom: 64px;
    border-radius: 25px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);

    > div {
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 767px) {
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0;
    }
  }

  &--description {
    border-radius: 25px 0 0 25px;
    background-color: #113A6B;
    gap: 1rem;

    > p {
      margin: 0;
      color: #F4F4F4;

      &:nth-child(2) {
        font-size: 18px;
        line-height: 1;
      }

      &:nth-child(3) {
        font-size: 36px;
        font-weight: 700;
        line-height: 1;
      }

      &:nth-child(4) {
        font-size: 18px;
        line-height: 1;
      }
    }

    >img {
      margin-top: auto;
    }

    .tos-privacy {
      margin-top: auto;
      margin-bottom: 1rem;
      color: #F4F4F4;
      text-align: center;
      font-size: 14px;
      line-height: 22px;

      > a {
        color: #F4F4F4;
        text-decoration: none;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 767px) {
      border-radius: 0;
      padding: 24px 0;
    }
  }

  &--form {
    border-radius: 0 25px 25px 0;
    background-color: white;
    padding: 36px 24px 24px;

    .account-information {
      &-row {
        display: flex;
        gap: 1rem;

        @media screen and (max-width: 767px) {
          display: block;
          gap: none;
        }
      }

      &-item {
        width: 100%;
        margin-bottom: 1rem;

        &.submit-button {
          height: 4rem;
          border-radius: 8px;
          font-size: 21px;
          font-weight: 700;
          color: #333;
          margin-bottom: 0;

          @media screen and (max-width: 767px) {
            font-size: 18px;
            height: 45px;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      border-radius: 0;
      padding: 20px 24px 12px;
    }

    .already-have-account {
      margin: 0;
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: black;
      text-align: center;

      > a {
        font-weight: 400;
        color: #2784BF;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .tos-privacy {
      font-size: 14px;
      line-height: 22px;
      color: #18181B;
      text-align: center;
      margin-top: 20px;

      > a {
        color: #18181B;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1200px) {
    background-position: top right;
    background-size: cover;
  }

  @media screen and (max-width: 767px) {
    background-image: none;
  }
}
</style>