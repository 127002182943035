export default {
  namespaced: true,
  state() {
    return {
      appLoading: false,
      irclickid: null,  // new state property for irclickid
    };
  },
  getters: {
    // Add a getter for irclickid
    getIrclickid: state => state.irclickid,
  },
  mutations: {
    setAppLoading(state, loading) {
      state.appLoading = loading;
    },
    // Add a mutation for irclickid
    SET_IRCLICKID(state, irclickid) {
      state.irclickid = irclickid;
    }
  },
  actions: {
    // Add an action for irclickid
    setIrclickid({ commit }, irclickid) {
      commit('SET_IRCLICKID', irclickid);
    },
  },
};
