import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import app from './app';
import account from './account';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    account,
  }
});
