export default {
  namespaced: true,
  state() {
    return {
      isAccountCreated: false
    };
  },
  getters: {
    getIsAccountCreated(state) {
      return state.isAccountCreated
    }
  },
  mutations: {
    setIsAccountCreated(state, value) {
      state.isAccountCreated = value;
    },
  },
  actions: {
  },
};